<template>
  <div class="photowall-block">
    <template v-for="(chunk, idx) in chunkedImages">
      <div
        :key="`block_${idx}`"
        ref="photoBlock"
        class="block"
        :class="[`block${idx + 1}`]"
        :style="{ height: winHeight }"
      >
        <div
          v-for="(photo, photoIdx) in chunk"
          :key="`block_${idx}_${photo.id}_${photoIdx}`"
          class="photo"
        >
          <img class="img" :src="photo.srcPreview" />
        </div>
      </div>
    </template>
    <infinite-loading class="infinite-loading" @infinite="loadmore">
      <div slot="spinner">
        <loader></loader>
      </div>
      <div slot="no-results" class="infinite-loading--empty">
        {{ $t("Photowall.noImages") }}
      </div>
      <div slot="no-more" class="no-more"></div>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { mapActions, mapMutations, mapState } from "vuex";
import Loader from "@/components/common/elements/Loader";
import { GET_PHOTO_WALL_IMAGES } from "@/store/modules/photowall/action-types";
import { photowall } from "@/store/modules/store.namespaces";
import { RESET_PHOTO_WALL } from "@/store/modules/photowall/mutation-types";

const IMAGES_CHUNKS_COUNT = 3;

export default {
  components: {
    Loader,
    InfiniteLoading,
  },
  data() {
    return {
      imgVisibleIndex: null,
      imgIsVisible: false,
      imgIsLoaded: false,
      winHeight: `${window.innerHeight}px`,
    };
  },
  computed: {
    ...mapState(photowall, ["lastImageKey"]),
    ...mapState(photowall, {
      images: "photos",
    }),
    chunkedImages() {
      if (!this.images.length) return [];
      return this.chunkImages();
    },
  },
  created() {
    this.getPhotoWallImages();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    this.resetPhotoWall();
  },
  methods: {
    ...mapActions(photowall, {
      getPhotoWallImages: GET_PHOTO_WALL_IMAGES,
    }),
    ...mapMutations(photowall, {
      resetPhotoWall: RESET_PHOTO_WALL,
    }),
    handleResize() {
      this.winHeight = `${window.innerHeight}px`;
    },
    loadmore($state) {
      this.getPhotoWallImages(this.lastImageKey).then(res => {
        if (res && res === "complete") {
          $state.complete();
        } else {
          setTimeout($state.loaded, 1000);
        }
      });
    },
    chunkImages() {
      let { images } = this;
      if (images.length === 1) {
        images = new Array(16).fill(this.images).flat();
      } else if (images.length < 40) {
        images = new Array(8).fill(this.images).flat();
      }
      const chunkSize = Math.floor(images.length / IMAGES_CHUNKS_COUNT);
      const chunkedImages = new Array(IMAGES_CHUNKS_COUNT);
      for (let i = 0; i < IMAGES_CHUNKS_COUNT; i++) {
        chunkedImages[i] = [];
      }
      let indexBlock = 0;
      for (let i = 0; i < images.length; i++) {
        indexBlock = i % IMAGES_CHUNKS_COUNT;
        if (chunkedImages[indexBlock].length < chunkSize) {
          chunkedImages[indexBlock].push(images[i]);
        }
      }
      console.log(chunkedImages);
      return chunkedImages;
    },
  },
};
</script>

<style scoped lang="less">
.infinite-status-prompt {
  display: none !important;
}

.photowall-block {
  height: 100%;
  transform: translate3d(0, 0, 0);

  .block {
    position: absolute;
    display: inline-block;
    width: 33%;

    .photo {
      width: 100%;
      margin-bottom: 6px;
      overflow: hidden;
      border-radius: 8px;

      .img {
        display: block;
        width: 100%;
      }
    }
  }

  .infinite-loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    ::v-deep .infinite-loading--empty {
      margin-top: 20px;
      font-size: 22px;
      text-align: center;
      color: #f00;
    }
  }

  .no-results {
    margin-top: 20px;
    font-size: 22px;
    text-align: center;
  }

  .block1 {
    top: 0;
    left: 0;
    animation: photowall-animation 60000ms linear 0s infinite normal forwards
      running;
  }

  .block2 {
    top: 0;
    left: 33.3%;
    transform: translateY(-300px);
    animation: photowall-animation 47000ms linear 0s infinite normal forwards
      running;
  }

  .block3 {
    top: 0;
    left: 66.7%;
    animation: photowall-animation 65000ms linear 0s infinite normal forwards
      running;
  }
}

@keyframes photowall-animation {
  from {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-50%);
  }

  to {
    transform: translateY(-100%);
  }
}
</style>
